import React, { Component } from 'react'

export class Terms extends Component {
    render() {
        return (
            <div style={style}>
                <h2>Terms and Conditions</h2>
                <h3>Restrictions</h3>
                <p>You are restricted from doing any of the following:</p>
                <ul>
                    <li>Attempting to steal information from this application other than that belonging to your username</li>
                    <li>Using this application in any way that impacts user access</li>
                    <li>Using this application in a way that could be harmful to the application</li>
                </ul>

                <h3>Your Content</h3>
                <p>Your content must be your own. You are not permitted to put any compromising information 
                    on this application. The application owner has the right to remove, without notice, any
                    of your content.
                </p>

                <h3>Limitation of Liability</h3>
                <p>In no event shall the owner of this application be held liable for anything arising 
                    out of your use of this application.
                </p>

                <h3>Variation of Terms</h3>
                <p>These terms may be revised at any time, and by using this application you agree to the terms 
                    presented.
                </p>

                <h3>Responsibility</h3>
                <p>It is your responsibility to exercise caution when using this application. The owner of the
                    application is not responsible for any data lost, stolen, or otherwise compromised.  
                </p>

                <h3>Notifications</h3>
                <p>You will not receive any notifications from this application unless you have explicitly
                    requested to receive notifications. You can opt out of notifications anytime by going
                    to the home page and opting out of notifications.
                </p>

            </div>
        )
    }
}

const style = {
    width: '75%'
}

export default Terms
