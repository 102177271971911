import React from 'react';
import MainPage from './components/MainPage.js'
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect
} from "react-router-dom";
import Amplify from 'aws-amplify'
import awsconfig from './aws-exports.js'
import { withAuthenticator } from 'aws-amplify-react'
import './App.css';

Amplify.configure(awsconfig);

class App extends React.Component {
  render() {
	  return (
		  <div className="App">
			<header className="App-header">
				<Router>
					<Switch>
						<Route exact path='/'>
							<MainPage />
						</Route>
						<Redirect to='/'/>
					</Switch>
				</Router>
			</header>
		  </div>
	  );
  }
}

export default withAuthenticator(App, true);
