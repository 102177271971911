import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import ReminderForm from './ReminderForm.js'

export class Reminders extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addReminder: false,
            editReminder: false,
            selectedReminder: null
        }
        this.bottom = "";
    }

    addReminder = () => {
        this.setState({
            addReminder: true
        });
        this.props.editing(true);
    }

    editReminder = () => {
        this.setState({
            editReminder: true
        });
        this.props.editing(true);
    }

    cancelReminder = () => {
        this.scrollToBottom();
        this.setState({
            addReminder: false,
            editReminder: false,
            selectedReminder: null
        });
        this.props.editing(false);
    }

    handleSubmitNewReminder = (reminder) => {
        this.props.addNewReminder(reminder);
        this.cancelReminder();
    }

    handleSubmitUpdateReminder = (reminder, oldName) => {
        this.props.updateReminder(reminder, oldName);
        this.cancelReminder();
    }

    deleteReminder = (reminder) => {
        this.props.deleteReminder(reminder);
        this.cancelReminder();
    }

    handleClick = (key) => {
        this.setState({
            selectedReminder: key,
            editReminder: true
        });
        this.props.editing(true);
    }

    nameExists = (name) => {
        return this.props.reminders["CUSTOM"][name] ? true : false
    }

    daysBeforeExists = (daysBefore, name) => {
        const days = parseInt(daysBefore);
        for (let key in this.props.reminders["CUSTOM"]) {
            if (key === name) continue;
            const reminder = this.props.reminders["CUSTOM"][key];
            if (parseInt(reminder.daysBefore) === days) return true;
        }
        return false;
    }

    render() {
        if (this.state.addReminder) {
            return this.displayNewReminderForm();
        } else if (this.state.editReminder) {
            return this.displayEditReminderForm();
        }

        return (
            <div>
                {this.buttonPanel()}
                <h2 ref={(el) => { this.bottom = el; }}>Default Reminders</h2>
                {this.renderDefaultReminders()}
                <h2>Custom Reminders</h2>
                {this.renderCustomReminders()}
            </div>
        )
    }

    buttonPanel = () => {
        return (
            <div>
                <Button variant="primary" id="addReminderButton" onClick={this.addReminder}>Add New Reminder</Button>
            </div>
        )
    }

    renderDefaultReminders = () => {
        const defaultReminders = ["DAILY", "WEEKLY", "MONTHLY"].map(reminder => {
            const reminderArr = this.props.reminders[reminder];
            return this.showReminders(reminder, reminderArr);
        });
        return defaultReminders;
    }

    renderCustomReminders = () => {
        const customReminders = (Object.keys(this.props.reminders["CUSTOM"]).map(reminder => {
            const customReminderArr = this.props.reminders["CUSTOM"][reminder]['birthdays'];
            return this.showReminders(reminder, customReminderArr, this.props.reminders["CUSTOM"][reminder].daysBefore);
        }));
        return customReminders;
    }

    showReminders = (reminder, arr, daysBefore = null) => {
        let title = reminder;
        let matchedBirthdays = this.props.birthdays.map(birthday => birthday.id)
            .filter(id => arr.includes(id));
        if (daysBefore) {
            title += `: ${daysBefore} days before`;
        }
        return (
            <div key={reminder} style={reminderStyle} className="reminderView" onClick={() => this.handleClick(reminder)}>
                {title}
                {this.showReminderContent(matchedBirthdays)}
            </div>
        )
    }

    showReminderContent = (arr) => {
        if (arr.length === 0) {
            return <div>none</div>
        }
        return arr.map(id => {
            const birthday = this.props.birthdays.find(birthday => birthday.id === id);
            if (!birthday) {
                return <></>
            }
            return (
                <li key={id}>{birthday.name}</li>
            )
        });
    }

    displayNewReminderForm = () => {
        const reminder = {
            birthdays: null,
            daysBefore: null
        };

        return (
            <div>
                <ReminderForm 
                    reminder={reminder}
                    cancelReminder={this.cancelReminder}
                    handleSubmit={this.handleSubmitNewReminder} 
                    allBirthdays={this.props.birthdays}
                    nameExists={this.nameExists} 
                    daysBeforeExists={this.daysBeforeExists} 
                />
            </div>
        )
    }

    displayEditReminderForm = () => {
        let currentReminder;
        let defaultReminders = ["DAILY", "WEEKLY", "MONTHLY"];
        let defaultReminder = false;
        let currentBirthdays;

        if (defaultReminders.includes(this.state.selectedReminder)) {
            currentReminder = this.props.reminders[this.state.selectedReminder];
            defaultReminder = true;
            currentBirthdays = currentReminder;
        } else {
            currentReminder = this.props.reminders["CUSTOM"][this.state.selectedReminder];
            currentBirthdays = currentReminder.birthdays;
        }

        const reminder = {
            name: this.state.selectedReminder,
            daysBefore: currentReminder.daysBefore,
            birthdays: currentBirthdays,
            defaultReminder: defaultReminder
        };

        return (
            <div>
                <ReminderForm 
                    reminder={reminder} 
                    cancelReminder={this.cancelReminder}
                    handleSubmit={this.handleSubmitUpdateReminder} 
                    allBirthdays={this.props.birthdays} 
                    nameExists={this.nameExists} 
                    daysBeforeExists={this.daysBeforeExists} 
                    deleteReminder={this.deleteReminder}
                />
            </div>
        )
    }

    scrollToBottom = () => {
        if (this.bottom) {
            this.bottom.scrollIntoView({ behavior: "smooth" });
        }
    }
        
    componentDidMount() {
        this.scrollToBottom();
    }

    componentWillUnmount() {
        this.props.editing(false);
    }

    componentDidUpdate() {
        if (!(this.state.addReminder || this.state.editReminder)) {
            this.scrollToBottom();
        }
    }
}

const reminderStyle = {
    border: '4px solid black',
    padding: '8px',
    margin: '8px',
    borderRadius: '12px'
}

export default Reminders
