import React, { Component } from 'react'
import Button from 'react-bootstrap/Button'

export class BirthdayForm extends Component {

    constructor(props) {
        super(props);
        this.fields = this.props.fields ? this.props.fields : {};
        this.state = {
            name: this.fields.name || '',
            birthMonth: parseInt(this.fields.birthMonth) || 1,
            birthDay: parseInt(this.fields.birthDay) || 1,
            gender: this.fields.gender || null,
            age: this.fields.age || 0,
            notes: (this.fields.notes || '').trim(),
            errorMessage: '',
            error: false
        }
        this.birthday = {};
        this.submitText = this.props.fields ? this.props.fields.submitText : "Submit new Birthday";
        this.bottom = "";
    }

    handleSubmit = (event) => {
        event.preventDefault();
        document.getElementById("submitButton").blur();
        try {
            this.validate();
        } catch(e) {
            this.setState({
                error: true,
                errorMessage: e.message
            });
            return;
        }
        this.props.successSubmit(this.birthday);
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    validate = () => {
        const { ...birthday } = this.state;
        const birthdayLimit = 100;

        if (this.props.limit && this.props.limit >= birthdayLimit) {
            throw new Error(`No more than ${birthdayLimit} birthdays are allowed`);
        }

        if (birthday.name.trim().length === 0) {
            throw new Error("Name can't be empty");
        }
        birthday.name = birthday.name.trim();

        this.validateDate(birthday.birthMonth, birthday.birthDay);

        birthday.notes = birthday.notes.trim();
        if (birthday.notes.length === 0) {
            birthday.notes = " ";
        }

        if (birthday.notes.length > 200) {
            throw new Error(`Notes have too many characters: Only 200 allowed, ${birthday.notes.length} detected`);
        }

        if (!this.validateIntRange(birthday.age, 0, 150)) {
            throw new Error(`Age must be between 0-150`);
        }

        // Leading 0's
        birthday.birthMonth = (birthday.birthMonth + '').padStart(2, '0').slice(-2);
        birthday.birthDay = (birthday.birthDay + '').padStart(2, '0').slice(-2);
        birthday.age = parseInt(birthday.age);
        this.birthday = birthday;
    }

    validateDate = (birthMonth, birthDay) => {
        if (!this.validateIntRange(birthMonth, 1, 12)) {
            throw new Error(`That is an illegal month: ${birthMonth}`);
        }
        if (!this.validateIntRange(birthDay, 1, 31)) {
            throw new Error(`That is an illegal day: ${birthDay}`);
        }
        const month = parseInt(birthMonth);
        const day = parseInt(birthDay);
        const daysInMonth = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        if (day > daysInMonth[month]) {
            throw new Error(`That is an illegal date: ${month}-${day}`);
        }
    }

    validateIntRange = (val, min, max) => {
        const parsed = parseInt(val);
        return parseFloat(val) === parsed && parsed >= min && parsed <= max;
    }

    render() {
        return (
            <>
            <form onSubmit={this.handleSubmit}
                style={formStyle}>
                <label>
                    *Name:
                    <input type="text" name="name" maxLength="40" value={this.state.name} onChange={this.handleInputChange} required/>
                </label>
                <br />
                <label>
                    *Month:
                    <input type="number" name="birthMonth" 
                    value={this.state.birthMonth} onChange={this.handleInputChange}
                    min="1" max="12" required/>
                </label>
                <br />
                <label>
                    *Day:
                    <input type="number" name="birthDay" 
                    value={this.state.birthDay} onChange={this.handleInputChange}
                    min="1" max="31" required/>
                </label>
                <br />
                <label>
                    Age (optional):
                    <input type="number" name="age"
                    value={this.state.age} onChange={this.handleInputChange}
                    min="0" max="150" />
                </label>
                <br />
                <div onChange={this.handleInputChange}>
                    Gender:
                    <input style={radioStyle} type="radio" value="U" name="gender" checked={this.state.gender === "U" || this.state.gender === null} onChange={this.handleInputChange} />Not Specified  
                    <input style={radioStyle} type="radio" value="M" name="gender" checked={this.state.gender === "M"} onChange={this.handleInputChange}/>Male  
                    <input style={radioStyle} type="radio" value="F" name="gender" checked={this.state.gender === "F"} onChange={this.handleInputChange}/>Female  
                </div>
                <br />
                <label>
                    Notes (optional):
                    <textarea type="textarea" name="notes"
                    value={this.state.notes} onChange={this.handleInputChange}
                    rows="4" cols="20"></textarea>
                </label>
                <br />
                {this.state.error && <div style={errorStyle}>Error: {this.state.errorMessage}</div>}
                <Button id="submitButton" variant="primary" onClick={this.handleSubmit}>{this.submitText}</Button>
            </form>
            <div ref={(el) => { this.bottom = el; }}></div>
            </>
        )
    }

    scrollToBottom = () => {
        this.bottom.scrollIntoView({ behavior: "smooth" });
    }
        
    componentDidMount() {
        this.scrollToBottom();
    }
}

const formStyle = {
    marginBottom: "20px"
}

const errorStyle = {
    color: 'red'
}

const radioStyle = {
    marginLeft: '6px'
}

export default BirthdayForm
