// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "nates-birthday-manager-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d2apxakmj7e5l3.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-east-1:2a473584-f8a8-41f3-8b28-2eef77ebed2f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_O49MTn69d",
    "aws_user_pools_web_client_id": "6tma4rvokav5r4jvg7mj852jr1",
    "oauth": {}
};


export default awsmobile;
