import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export class HowToUse extends Component {
    render() {
        return (
            <div style={howToStyle}>
                <h2>How To Use</h2>
                <div>
                    <h3>Birthday Overview</h3>
                    <p>To work with birthdays, go to the <Link to='/birthdays'>birthday tab</Link>. 
                        You will be shown a table of saved birthdays. 
                        Press "Add new birthday" to add new birthdays.
                        You can click any birthday in the table to view or make changes to it.
                    </p>

                    <h3>Reminder Overview</h3>
                    <p>Reminders are how you choose when you'll get reminded about particular birthdays.
                        There are 3 default reminders: <em>DAILY, WEEKLY, and MONTHLY</em>. These reminders
                        are triggered the day of a birthday, the week of (starting Sunday) and the month (starting
                        the first of the month), respectively.
                        In addition to the default reminders, the ability to create your own reminder is
                        possible too. This means you can choose when you get reminded about anyone's birthday.
                    </p>

                    <h3>Saving and Cancelling Changes</h3>
                    <p>In order for your changes to be remembered, make sure to click the 'Save Changes' button.
                        If you made some changes and would like to undo them all, click the 'Cancel Changes' button. 
                        <strong> Note:</strong> Only changes made since the last save will be deleted. 
                    </p>

                    <h3>Receiving Email Reminders</h3>
                    <p>You will be reminded of upcoming birthdays via the email address you provided during
                        account creation. In order to recieve emails, make sure you have clicked the 
                        <em> Click here to subscribe</em> button. If you would ever like to unsubscribe from
                        email notifications, click the <em>Click here to unsubscribe</em> button. <strong>Note: </strong>
                        You will only be reminded of birthdays that have been included in a reminder.
                    </p>
                </div>
            </div>
        )
    }
}

const howToStyle = {
    width: '75%'
}

export default HowToUse
