import React from 'react';
import Table from 'react-bootstrap/Table';
import Birthday from './Birthday.js'
import './BirthdayTable.css'


class BirthdayTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            prefixSearch: ""
        }
        this.messagesEnd = "";
    }

    handleSearchBarChange = (event) => {
        event.preventDefault();
        this.setState({
            prefixSearch: event.target.value
        });
    }

    render() {
        return (
            <div>
                <h3>Birthdays</h3>
                {this.renderSearchBar()}
                <Table striped bordered hover variant="dark" style={tableStyle}>
                    <thead>
                        {this.renderTableHead()}
                    </thead>
                    <tbody>
                        {this.renderTableBody()}
                    </tbody>
                </Table>
            </div>
        )
    }

    renderSearchBar = () => {
        return (
            <div ref={(el) => { this.messagesEnd = el; }}>
                <label>
                    Search name:
                    <input type="text" 
                        id="searchBar" 
                        name="searchBar" 
                        value={this.state.prefixSearch}
                        onChange={this.handleSearchBarChange}
                    />
                </label>
            </div>
        )
    }

    sortBy = (type) => {
        this.props.sortBy(type);
        this.updateTableCss(type);
    }

    renderTableHead = () => {
        return (
            <tr key='tableHead'>
                <th>#</th>
                <th className="birthdayRowItem" onClick={this.sortBy.bind(this, "NAME")}>Name<i id="nameHeader" className='inactiveArrow'></i></th>
                <th className="birthdayRowItem" onClick={this.sortBy.bind(this, "BIRTHDAY")}>Birthday<i id='birthdayHeader' className='activeArrow'></i></th>
            </tr>
        )
    }

    renderTableBody = () => {
        return this.props.birthdays.map((birthday, index) => {
            const showBirthday = birthday.name.toUpperCase().includes(this.state.prefixSearch.toUpperCase());
            if (showBirthday) {
                return (
                        <tr key={birthday.id} className="birthdayRowItem" onClick={() => this.openBirthday(index)}>
                            <td>{index+1}</td>
                            <Birthday birthday={birthday}/>
                        </tr>
                )
            } else {
                return (<React.Fragment key={birthday.id}></React.Fragment>)
            }
        });
    }

    openBirthday = (index) => {
        this.props.openBirthday(index);
    }

    // https://stackoverflow.com/questions/37620694/how-to-scroll-to-bottom-in-react
    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }
        
    componentDidMount() {
        this.scrollToBottom();
        this.updateTableCss(this.props.sortingMethod);
    }
        
    componentDidUpdate() {
        this.scrollToBottom();
        this.updateTableCss(this.props.sortingMethod);
    }

    updateTableCss = (type) => {
        if (type === "NAME") {
            document.getElementById("nameHeader").className = "activeArrow";
            document.getElementById("birthdayHeader").className = "inactiveArrow";
        } else {
            document.getElementById("nameHeader").className = "inactiveArrow";
            document.getElementById("birthdayHeader").className = "activeArrow";
        }
    }
}

const tableStyle = {
    margin: '5px'
}

export default BirthdayTable
