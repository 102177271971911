import React, { Component } from 'react'

export class Birthday extends Component {
    render() {
        const birthday = this.props.birthday;
        return (
            <>
                <td>{birthday.name}</td>
                <td>{birthday.birthday}</td>
            </>
        )
    }
}

export default Birthday
