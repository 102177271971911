import React from 'react'
import './Header.css'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            savingChanges: false,
            cancellingChanges: false
        }
    }

    addRandomBirthday = (e) => {
        e.preventDefault();
        this.props.addBirthday();
    }

    saveChanges = (e) => {
        e.preventDefault();
        this.setState({
            savingChanges: true
        });

        this.props.saveChanges(() => {
            this.setState({
                savingChanges: false
            });
            document.getElementById('saveChangesButton').blur();
        });
    }

    cancelChanges = (e) => {
        e.preventDefault();
        this.setState({
            cancellingChanges: true
        });

        this.props.cancelChanges(() => {
            this.setState({
                cancellingChanges: false
            });
            document.getElementById('cancelChangesButton').blur();
        }); 
    }

    render() {
        return (
            <div id="header">
                Welcome to Nate's Birthday Manager
                <ul id="links">
                    <li><Link to='/'>Home</Link></li>
                    <li><Link to='/birthdays'>View Birthdays</Link></li>
                    <li><Link to='/reminders'>View Reminders</Link></li>
                </ul>
                {this.buttonPanel()}
            </div>
        )
    }

    buttonPanel = () => {
        if (this.props.hideButtons) return <></>;
        const saveChangesText = this.state.savingChanges ? "Saving..." : "Save Changes";
        const cancelChangesText = this.state.cancellingChanges ? "Cancelling..." : "Cancel Changes";
        return (
            <div style={buttonStyle}>
                <Button variant="success" id="saveChangesButton" onClick={this.saveChanges} disabled={this.state.savingChanges} >{this.state.savingChanges && this.spinner()}  {saveChangesText}</Button>
                <Button variant="danger" id="cancelChangesButton" onClick={this.cancelChanges} disabled={this.state.cancellingChanges} >{this.state.cancellingChanges && this.spinner()}  {cancelChangesText}</Button>
            </div>
        )
    }

    //https://mdbootstrap.com/docs/react/components/spinners/
    spinner = () => {
        return (
            <>
                <div className="spinner-border spinner-border-sm" role="status">
                </div>
            </>
        )
    }
}

const buttonStyle = {
    margin: '10px',
    display: 'inline-block'
}

export default Header;