import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import App from './App';
import config from './config.js'
import Amplify from 'aws-amplify';

Amplify.configure({
    API: {
        endpoints: [
            {
                name: "BirthdayAPI",
                endpoint: config.ApiGateway.URL
            }
        ]
    }
});

ReactDOM.render(<App />, document.getElementById('root'));
