import React, { Component } from 'react'
import { Button } from 'react-bootstrap'

export class ReminderForm extends Component {
    constructor(props) {
        super(props);
        const reminder = this.props.reminder;
        this.defaultReminder = reminder.defaultReminder ? true : false;
        this.state = {
            daysBefore: reminder.daysBefore || 1,
            name: reminder.name || "",
            birthdays: reminder.birthdays || [],
            error: false,
            errorMessage: ""
        };
        this.originalName = this.state.name;
        this.validatedReminder = {};
        this.bottom = "";
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleCheckbox = (id, unchecked) => {
        if (unchecked) {
            this.setState({
                birthdays: this.state.birthdays.filter(birthdays => birthdays !== id)
            });
        } else {
            this.setState({
                birthdays: this.state.birthdays.concat(id)
            });
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        document.getElementById("submitButton").blur();
        try {
            this.validate();
        } catch(error) {
            this.setState({
                error: true,
                errorMessage: error.message
            });
            return;
        }
        
        const reminder = {
            daysBefore: this.validatedReminder.daysBefore,
            name: this.validatedReminder.name,
            birthdays: this.validatedReminder.birthdays
        };

        this.props.handleSubmit(reminder, this.originalName);
    }

    addAll = () => {
        document.getElementById("addAllButton").blur();
        this.setState({
            birthdays: this.props.allBirthdays.map(birthday => birthday.id)
        });
    }

    removeAll = () => {
        document.getElementById("removeAllButton").blur();
        this.setState({
            birthdays: []
        });
    }

    validate = () => {
        const reservedNames = ["DAILY", "WEEKLY", "MONTHLY"];
        const name = this.state.name.split(" ").filter(item => item.length > 0).join(" ").trim();
        if (this.defaultReminder) {
            if (reservedNames.includes(!name)) {
                throw new Error(`Can't change the name of a default reminder`);
            }
        } else {
            if (name.length === 0) {
                throw new Error(`Name must have at least one none-whitespace character`);
            }
            if (this.originalName !== name && this.props.nameExists(name)) {
                throw new Error(`A reminder with that name already exists`);
            }
            if (reservedNames.includes(name.toUpperCase())) {
                throw new Error(`That is a reserved name. Please use another`);
            }
            if (this.props.daysBeforeExists(this.state.daysBefore, this.originalName)) {
                throw new Error(`A reminder with that time already exists`);
            }
            if (parseInt(this.state.daysBefore) < 1 || parseInt(this.state.daysBefore) > 364) {
                throw new Error(`Illegal reminder time range`);
            }
            if (!/^[0-9]+$/.test(this.state.daysBefore)) {
                throw new Error(`Days before must be an integer`);
            }
        }

        this.validatedReminder.name = name;
        this.validatedReminder.daysBefore = parseInt(this.state.daysBefore);
        this.validatedReminder.birthdays = this.state.birthdays;
    }

    render() {
        return (
            <div style={formStyle}>
                Reminder Form
                <Button variant='secondary' onClick={this.props.cancelReminder}>Cancel</Button>
                <div ref={(el) => { this.bottom = el; }}>
                    {this.displayForm()}
                </div>
            </div>
        )
    }

    displayForm = () => {
        return (
            <div>
                <form onSubmit={this.handleSubmit} style={formStyle}>
                    {this.formNameField()}
                    <br />
                    {this.formDaysBeforeField()}
                    <br />
                    <h3>Birthdays</h3>
                    {this.displayButtons()}
                    {this.displayBirthdays()}
                    {this.state.error && <div>Error: {this.state.errorMessage}</div>}
                    <Button id="submitButton" variant="outline-primary" onClick={this.handleSubmit}>Submit</Button>
                </form>
                {this.renderDeleteButton()}
            </div>
        )
    }

    formNameField = () => {
        if (this.defaultReminder) {
            return (
                <label>
                    *Name:
                    <input type="text" name="name" value={this.state.name} disabled={true} />
                </label>
            )
        }

        return (
            <label>
                *Name:
                <input type="text" name="name" maxLength="20" value={this.state.name} onChange={this.handleInputChange} required/>
            </label>
        )
    }

    formDaysBeforeField = () => {
        if (!this.defaultReminder) {
            return (
                <label>
                    *Days Before:
                    <input type="number" name="daysBefore" 
                    value={this.state.daysBefore} onChange={this.handleInputChange}
                    min="1" max="364" required />
                </label>
            )
        }
        return (<span></span>)
    }

    displayButtons = () => {
        return (
            <div>
                <Button id="addAllButton" variant="outline-success" onClick={this.addAll}>
                    Add all
                </Button>
                <Button id="removeAllButton" variant="outline-danger" onClick={this.removeAll}>
                    Remove all
                </Button>
            </div>
        )
    }

    displayBirthdays = () => {
        if (this.props.allBirthdays.length === 0) {
            return <div>No birthdays</div>
        }
        return (
            this.props.allBirthdays.map(birthday => {
                const isChecked = this.state.birthdays.find(id => id === birthday.id) ? true : false;
                return (
                    <div key={birthday.id} style={birthdayStyle}>
                        <label>
                            <input type="checkbox" 
                                    checked={isChecked}
                                    onChange={this.handleCheckbox.bind(this, birthday.id, isChecked)}
                                    style={checkboxStyle} />
                            {`${birthday.name} (${birthday.birthday})`}
                        </label>
                    </div>
                )
            })
        )
    }

    renderDeleteButton = () => {
        if (this.defaultReminder || !this.props.deleteReminder) {
            return (<></>)
        } else {
            return (
                <Button variant="danger" onClick={() => this.props.deleteReminder(this.originalName)}>
                    Delete Reminder
                </Button>
            )
        }
    }

    scrollToBottom = () => {
        this.bottom.scrollIntoView({ behavior: "smooth" });
    }
        
    componentDidMount() {
        this.scrollToBottom();
    }
}

const birthdayStyle = {
    margin: '3px'
}

const checkboxStyle = {
    margin: '5px'
}

const formStyle = {
    marginBottom: '10px'
}

export default ReminderForm
