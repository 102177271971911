import React from 'react'
import BirthdayTable from './BirthdayTable.js'
import Button from 'react-bootstrap/Button'
import BirthdayForm from './BirthdayForm.js'

export class Birthdays extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            newBirthdayForm: false,
            editBirthdayForm: false,
            selectedIndex: null
        }
    }

    addBirthday = (birthday) => {
        this.props.addBirthday(birthday);
        this.resetState();
    }

    handleAddBirthday = () => {
        this.setState({
            newBirthdayForm: true
        });
        this.props.editing(true);
    }

    render() {
        return (
            <div style={style}>
                {this.showContent()}
            </div>
        )
    }

    showContent = () => {
        if (this.state.editBirthdayForm) {
            return this.showEditForm();
        } else if (this.state.newBirthdayForm) {
            return this.showAddBirthdayForm();
        }
        return this.showBirthdays();
    }

    sortBy = (criteria) => {
        this.props.sortBy(criteria);
    }

    showBirthdays = () => {
        let birthdayContent;
        if (this.props.birthdays.length === 0) {
            birthdayContent = (<p>You don't have any saved birthdays!<br /> Press 'Add New Birthday' to get started!</p>)
        } else {
            birthdayContent = (<BirthdayTable birthdays={this.props.birthdays} 
                openBirthday={this.openBirthday}
                sortBy={this.sortBy}
                sortingMethod={this.props.sortingMethod}
                />
            );
        }

        return (
            <div>
                <Button variant="primary" id="addBirthdayButton" onClick={this.handleAddBirthday}>Add New Birthday</Button>
                {birthdayContent}
            </div>
        )
    }

    showEditForm = () => {
        return (
            <div>
				<BirthdayForm successSubmit={this.editBirthday} fields={this.getSelectedFields()}/>
                <Button variant="danger" id="deleteBirthdayButton" className="editBirthdayButton" onClick={this.deleteBirthday}>Delete Birthday</Button>
                <Button variant="light" id="cancelBirthdayButton" className="editBirthdayButton" onClick={this.resetState}>Cancel</Button>
            </div>
        )
    }

    showAddBirthdayForm = () => {
        return (
            <div>
				<BirthdayForm successSubmit={this.addBirthday} limit={this.props.limit} />
                <Button variant="light" id="cancelBirthdayButton" className="editBirthdayButton" onClick={this.resetState}>Cancel</Button>
            </div>
        )
    }

    getSelectedFields = () => {
        let fields = {};
        let birthday = this.props.birthdays[this.state.selectedIndex];
        fields.birthMonth = parseInt(birthday.birthday.substring(0, 2));
        fields.birthDay = parseInt(birthday.birthday.substring(3));
        fields.name = birthday.name;
        fields.age = birthday.age == null ? 0 : parseInt(birthday.age);
        fields.notes = birthday.notes;
        fields.gender = birthday.gender;
        fields.submitText = 'Update Birthday';
        return fields;
    }

    editBirthday = (birthday) => {
        this.props.updateBirthday(this.state.selectedIndex, birthday);
        this.resetState();
    }

    openBirthday = (index) => {
        this.setState({
            editBirthdayForm: true,
            selectedIndex: index
        });
        this.props.editing(true);
    }

    deleteBirthday = () => {
        this.props.deleteBirthday(this.state.selectedIndex);
        this.resetState();
    }

    resetState = () => {
        this.setState({
            editBirthdayForm: false,
            newBirthdayForm: false,
            selectedIndex: null
        });
        this.props.editing(false);
    }

    componentWillUnmount = () => {
        this.props.editing(false);
    }

}

const style = {
    margin: '10px'
}

export default Birthdays
