import React from 'react';
import HowToUse from './HowToUse.js'
import Header from './Header.js'
import Birthdays from './Birthdays.js'
import Reminders from './Reminders.js'
import Home from './Home.js'
import Terms from './Terms.js'
import { Auth, API } from 'aws-amplify';
import {
	BrowserRouter as Router,
	Switch,
	Route
} from "react-router-dom";

import '../App.css';

const uuid = require('uuid/v4');

class MainPage extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			birthdays: [],
			reminders: {},
			loaded: false,
			sortingMethod: "BIRTHDAY",
			hideButtons: false,
			subscribed: false
		}
		this.user = {};
	}

	sortBy = (criteria) => {
		const newBirthdays = this.state.birthdays;
		this.sortBirthdays(newBirthdays, criteria);
		this.setState({
			sortingMethod: criteria.toUpperCase(),
			birthdays: newBirthdays
		});
	}

	sortBirthdays = (arr, criteria) => {
		criteria = criteria || this.state.sortingMethod;
		if (criteria === "BIRTHDAY") {
			this.sortByBirthday(arr);
		} else {
			this.sortByName(arr);
		}

		this.setState({
			birthdays: arr
		});
	}

	sortByName = (arr) => {
		arr.sort((a,b) => {
			return a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1
		});
	}

	sortByBirthday = (arr) => {
		arr.sort((a, b) => {
			let aDay = parseInt(a.birthday.substring(0, 2));
			let bDay = parseInt(b.birthday.substring(0, 2));
			if (aDay !== bDay) {
				return aDay - bDay;
			}

			let aMonth = parseInt(a.birthday.substring(3));
			let bMonth = parseInt(b.birthday.substring(3));
			if (aMonth !== bMonth) {
				return aMonth - bMonth;
			}

			return 0;
		});
	}

	saveChanges = (callback) => {
		if (this.state.birthdays === this.user.birthdays && this.state.reminders === this.user.lists) {
			console.log("No changes detected");
			callback();
			return;
		}

		this.user.birthdays = this.state.birthdays;
		if (this.state.reminders['WEEKLY']) {
			this.user.lists = this.state.reminders;
		}

		API.post('BirthdayAPI', '/save-changes', {
			body: {
				user: this.user
			}
		})
		.then((data) => {
			console.log('Success updating:', data);
			callback();
		})
		.catch((err) => console.error("ERR: " + err));
	}

	cancelChanges = (callback) => {
		this.setState({
			birthdays: this.user.birthdays,
			reminders: this.user.lists,
			sortingMethod: "BIRTHDAY"
		});
		callback();
	}

	componentDidMount = () => {
		Auth.currentAuthenticatedUser()
		.then(user => {
			const id = user.username;
			this.loadUser(id);
		})
		.catch(err => console.log(err));
	}

	loadUser = (id) => {
		API.get("BirthdayAPI", "/user", {
			queryStringParameters: {
				id: id
			}
		})
		.then((result) => {
				this.sortBirthdays(result.birthdays);
				this.user = result;
				this.setState({
					birthdays: [...result.birthdays],
					reminders: JSON.parse(JSON.stringify(result.lists)),
					loaded: true,
					subscribed: result.subscribed ? true : false
				});
			}
		)
		.catch(
			() => {
				console.log("No user found");
			}
		);
	}

	addBirthday = (birthday) => {
		let newBirthday = {
			name: birthday.name,
			birthday: `${birthday.birthMonth}-${birthday.birthDay}`,
			gender: birthday.gender,
			age: birthday.age,
			notes: birthday.notes,
			id: uuid()
		}

		if (this.birthdayAlreadyExists(newBirthday)) {
			console.log("Birthday already exists");
			return;
		}

		const newBirthdays = [...this.state.birthdays, newBirthday];
		this.sortBirthdays(newBirthdays);
		this.setState({
			birthdays: newBirthdays
		});
	}

	deleteBirthday = (index) => {
		const reminders = JSON.parse(JSON.stringify(this.state.reminders));
		const birthdayId = this.state.birthdays[index].id;
		reminders["DAILY"] = reminders["DAILY"].filter(id => id !== birthdayId);
		reminders["WEEKLY"] = reminders["WEEKLY"].filter(id => id !== birthdayId);
		reminders["MONTHLY"] = reminders["MONTHLY"].filter(id => id !== birthdayId);
		for (let customReminder in reminders["CUSTOM"]) {
			reminders["CUSTOM"][customReminder].birthdays = reminders["CUSTOM"][customReminder].birthdays.filter(id => id !== birthdayId);
		}

		this.setState({
			birthdays: this.state.birthdays.filter((birthday, birthdayIndex) => birthdayIndex !== index),
			reminders: reminders
		});
	}

	removeIdFromList(id, list) {
		return list.filter(a => a !== id);
	}

	updateBirthday = (index, updatedBirthday) => {
		let newBirthday = {
			name: updatedBirthday.name,
			birthday: `${updatedBirthday.birthMonth}-${updatedBirthday.birthDay}`,
			gender: updatedBirthday.gender,
			age: updatedBirthday.age,
			notes: updatedBirthday.notes,
			id: this.state.birthdays[index].id
		}

		if (this.birthdayAlreadyExists(newBirthday, index)) {
			console.log("Birthday already exists");
			return;
		}

		const newBirthdayList = [...this.state.birthdays];
		newBirthdayList[index] = newBirthday;

		this.sortBirthdays(newBirthdayList);
		this.setState({
			birthdays: newBirthdayList
		});
	}

	birthdayAlreadyExists = (newBirthday, index) => {
		let matched = false;
		this.state.birthdays.forEach((birthday, i) => {
			if (i !== index && birthday.name === newBirthday.name &&
				birthday.birthday === newBirthday.birthday) {
				matched = true;
			}
		});
		return matched;
	}

	// Assume it's a custom reminder
	addNewReminder = (reminder) => {
		const newReminderObj = JSON.parse(JSON.stringify(this.state.reminders));
		newReminderObj["CUSTOM"][reminder.name] = {
			daysBefore: reminder.daysBefore,
			birthdays: reminder.birthdays
		};

		this.setState({
			reminders: newReminderObj
		});
	}

	updateReminder = (reminder, oldName) => {
		const defaultReminders = ["DAILY", "WEEKLY", "MONTHLY"];
		const reminders = JSON.parse(JSON.stringify(this.state.reminders));

		if (defaultReminders.includes(oldName)) {
			reminders[oldName] = reminder.birthdays;
		} else {
			delete reminders["CUSTOM"][oldName];
			reminders["CUSTOM"][reminder.name] = reminder;
		}

		this.setState({
			reminders: reminders
		});
	}

	deleteReminder = (reminder) => {
		const reminders = JSON.parse(JSON.stringify(this.state.reminders));
		delete reminders["CUSTOM"][reminder];
		this.setState({
			reminders
		});
	}

	hideEditButtons = (status) => {
		this.setState({
			hideButtons: status
		});
	}

	toggleSubscription = (callback) => {
		const subscribed = !this.state.subscribed;

		API.post('BirthdayAPI', '/subscribe-to-emails', {
			body: {
				id: this.user.id,
				subscribed: subscribed
			}
		})
		.then((data) => {
			console.log('Success updating:', data);
			this.setState({
				subscribed
			});
			callback();
		})
		.catch((err) => console.error("ERR: " + err));
	}

	render() {
		return ( 
			<div className = "App" >
				<header className = "App-header" >
					{this.state.loaded ? this.displayLoaded() : this.displayLoading()}
				</header>
			</div>
		);
	}

	displayLoaded = () => {
		return ( 
			<Router >
				<Header addBirthday={this.addRandomBirthday}
					saveChanges={this.saveChanges}
					cancelChanges={this.cancelChanges}
					hideButtons={this.state.hideButtons}
				/> 
				<Switch>
					<Route exact path='/'>
						<Home birthdays={this.state.birthdays} 
							subscribed={this.state.subscribed} 
							toggleSubscription={this.toggleSubscription}
						/>
					</Route> 
					<Route path='/birthdays'>
						<Birthdays birthdays={this.state.birthdays}
							updateBirthday={this.updateBirthday}
							addBirthday={this.addBirthday}
							deleteBirthday={this.deleteBirthday}
							sortBy={this.sortBy}
							limit={this.state.birthdays.length}
							editing={this.hideEditButtons}
							sortingMethod={this.state.sortingMethod}
						/> 
					</Route> 
					<Route path='/reminders'>
						<Reminders reminders={this.state.reminders}
							birthdays={this.state.birthdays}
							addNewReminder={this.addNewReminder}
							updateReminder={this.updateReminder}
							deleteReminder={this.deleteReminder}
							editing={this.hideEditButtons}
						/> 
					</Route> 
					<Route path='/how-to-use'>
						<HowToUse />
					</Route> 
					<Route path='/terms-and-conditions'>
						<Terms />
					</Route>
				</Switch>
			</Router>
		)
	}

	displayLoading = () => {
		return ( 
			<div> 
				Loading... 
			</div>
		)
	}

}

export default MainPage;