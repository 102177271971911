import React, { Component } from 'react'
import BirthdayDownloader from './BirthdayDownloader.js';
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'

export class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    handleClick = () => {
        this.setState({
            loading: true
        });

        this.props.toggleSubscription(() => {
            this.setState({
                loading: false
            });
        });
        document.getElementById("subscriptionButton").blur();
    }

    render() {
        return (
            <div>
                Home
                {this.showTerms()}
                <BirthdayDownloader birthdays={this.props.birthdays} />
                {this.showSubscription()}
                {this.showHowTo()}
            </div>
        )
    }

    showTerms = () => {
        return (
            <div>By using this application, you agree to the <Link to='terms-and-conditions'>Terms and Conditions</Link></div>
        )
    }

    showSubscription = () => {
        return (
            <div>
                <p>You are {this.props.subscribed ? "" : "not"} subscribed for email notifications</p>
                <Button variant="primary" onClick={this.handleClick} id="subscriptionButton">
                    {this.state.loading && this.spinner()}
                    Click here to {this.props.subscribed ? "unsubscribe" : "subscribe"}
                </Button>
            </div>
        )
    }

    showHowTo = () => {
        return (
            <Link to='/how-to-use'>
                <Button variant="light">How to use</Button>
            </Link>
        )
    }

    spinner = () => {
        return (
            <>
                <div className="spinner-border spinner-border-sm" role="status">
                </div>
            </>
        )
    }
}

export default Home
