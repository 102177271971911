import React, { Component } from 'react'
import CsvDownloader from 'react-csv-downloader';
import Button from 'react-bootstrap/Button'

export class BirthdayDownloader extends Component {
    render() {
        const columns = [
            {
                id: 'name',
                displayName: 'Name'
            }, {
                id: 'month',
                displayName: 'Month'
            }, {
                id: 'day',
                displayName: 'Day'
            }, {
                id: 'age',
                displayName: 'Age'
            }, {
                id: 'notes',
                displayName: 'Notes'
            }, {
                id: 'gender',
                displayName: 'Gender'
            }
        ];

        const date = this.getDate();
        
         
        const datas = this.props.birthdays.map(birthday => this.rowFromBirthday(birthday));
        return (
            <div>
                <CsvDownloader
                    filename='Birthdays'
                    separator=','
                    columns={columns}
                    datas={datas}
                    suffix={date}
                    >
                        <Button variant="info" id="downloadBirthdaysButton" onClick={() => document.getElementById('downloadBirthdaysButton').blur()}>
                            Download Birthdays
                        </Button>
                </CsvDownloader>
            </div>
        )
    }

    rowFromBirthday = (birthday) => {
        return {
            name: birthday.name,
            month: birthday.birthday.substring(0, 2),
            day: birthday.birthday.substring(3),
            age: birthday.age,
            notes: birthday.notes,
            gender: birthday.gender === null ? "N/A" : birthday.gender
        }
    }

    getDate = () => {
        const date = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
        return date.substring(0, date.indexOf(',')).split('/').join('-');
    }
}

export default BirthdayDownloader
